import { WorkspaceState } from '@/lib/common/types'
import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

/**
 * We'll now store "isDirty" both at the workspace level and for each individual tab.
 * Whenever we modify a tab, we'll mark that tab's "isDirty" as true, and we'll also
 * set the workspace-level "isDirty" to true. "setClean()" will mark both the workspace
 * and all relevant tabs' "isDirty" values as false.
 */

const initialState: {
  [user_id: string]: WorkspaceState
} = {}

const workspaceStateSlice = createSlice({
  name: 'queryResults',
  initialState,
  reducers: {
    initWorkspaceState(state, action) {
      const { user_id, organization_id, workspaceState } = action.payload
      const stateKey = organization_id
        ? `${organization_id}-${user_id}`
        : user_id
      return {
        ...state,
        [stateKey]: workspaceState,
      }
    },
    /**
     * Sets the workspace's isDirty to false, and all tabs' isDirty to false,
     * for the given workspace.
     */
    setClean(state, action) {
      const { user_id, organization_id } = action.payload
      const stateKey = organization_id
        ? `${organization_id}-${user_id}`
        : user_id
      const currentWS = state[stateKey]

      if (!currentWS?.tabs) return state

      return {
        ...state,
        [stateKey]: {
          ...currentWS,
          isDirty: false,
          lastUpdatedAt: Date.now(),
          // Set each tab's isDirty = false
          tabs: currentWS.tabs.map((tab) => ({
            ...tab,
            isDirty: false,
          })),
        },
      }
    },
    createQueryTab(state, action) {
      const { user_id, organization_id, name, code } = action.payload
      const stateKey = organization_id
        ? `${organization_id}-${user_id}`
        : user_id
      const currentWS = state[stateKey]

      return {
        ...state,
        [stateKey]: {
          ...currentWS,
          isDirty: true, // Mark workspace dirty
          tabs: [
            ...(currentWS?.tabs || []),
            {
              type: 'query',
              id: uuidv4(),
              name: name || new Date().toISOString(),
              code: code || '',
              isDirty: true, // Mark new tab as dirty
            },
          ],
          selectedTabIndex: currentWS?.tabs?.length || 0,
          lastUpdatedAt: Date.now(),
        },
      }
    },
    createTableTab(state, action) {
      const { user_id, organization_id, name } = action.payload
      const stateKey = organization_id
        ? `${organization_id}-${user_id}`
        : user_id
      const currentWS = state[stateKey]

      return {
        ...state,
        [stateKey]: {
          ...currentWS,
          isDirty: true, // Mark workspace dirty
          tabs: [
            {
              type: 'table',
              id: uuidv4(),
              name,
              isDirty: true, // Mark new table tab as dirty
            },
            ...(currentWS?.tabs?.filter((tab) => tab.type !== 'table') || []),
          ],
          selectedTabIndex: 0,
          lastUpdatedAt: Date.now(),
        },
      }
    },
    changeTabName(state, action) {
      const { user_id, organization_id, tab_id, name } = action.payload
      const stateKey = organization_id
        ? `${organization_id}-${user_id}`
        : user_id
      const currentWS = state[stateKey]

      if (!currentWS?.tabs) return state

      return {
        ...state,
        [stateKey]: {
          ...currentWS,
          isDirty: true, // Mark workspace dirty
          tabs: currentWS.tabs.map((tab) =>
            tab.id === tab_id
              ? {
                  ...tab,
                  name,
                  isDirty: true,
                }
              : tab,
          ),
          lastUpdatedAt: Date.now(),
        },
      }
    },
    updateTabCode(state, action) {
      const { user_id, organization_id, tab_id, code } = action.payload
      const stateKey = organization_id
        ? `${organization_id}-${user_id}`
        : user_id
      const currentWS = state[stateKey]

      if (!currentWS?.tabs) return state

      return {
        ...state,
        [stateKey]: {
          ...currentWS,
          isDirty: true, // Mark workspace dirty
          tabs: currentWS.tabs.map((tab) =>
            tab.id === tab_id
              ? {
                  ...tab,
                  code,
                  isDirty: true,
                }
              : tab,
          ),
          lastUpdatedAt: Date.now(),
        },
      }
    },
    deleteTab(state, action) {
      const { user_id, organization_id, tab_id, indexToDel } = action.payload
      const stateKey = organization_id
        ? `${organization_id}-${user_id}`
        : user_id
      const currentWS = state[stateKey]

      if (!currentWS?.tabs) return state

      let newTabIndex = -1

      if (currentWS.selectedTabIndex === indexToDel) {
        newTabIndex = Math.min(
          currentWS.selectedTabIndex,
          currentWS.tabs.length - 2,
        )
      } else {
        if (currentWS.selectedTabIndex < indexToDel) {
          newTabIndex = currentWS.selectedTabIndex
        } else {
          newTabIndex = currentWS.selectedTabIndex - 1
        }
      }

      return {
        ...state,
        [stateKey]: {
          ...currentWS,
          isDirty: true, // Mark workspace dirty
          tabs: currentWS.tabs.filter((tab) => tab.id !== tab_id),
          selectedTabIndex: newTabIndex,
          lastUpdatedAt: Date.now(),
        },
      }
    },
    selectTab(state, action) {
      const { user_id, organization_id, index } = action.payload
      const stateKey = organization_id
        ? `${organization_id}-${user_id}`
        : user_id
      const currentWS = state[stateKey]

      if (!currentWS) return state

      return {
        ...state,
        [stateKey]: {
          ...currentWS,
          // Selecting a tab by itself might not necessarily mean the workspace is dirty,
          // so we won't toggle isDirty here unless desired.
          selectedTabIndex: index,
          lastUpdatedAt: Date.now(),
        },
      }
    },
    createQuery(state, action) {
      const { user_id, organization_id, tab_id, query_id, query } =
        action.payload
      const stateKey = organization_id
        ? `${organization_id}-${user_id}`
        : user_id
      const currentWS = state[stateKey]

      if (!currentWS?.tabs) return state

      return {
        ...state,
        [stateKey]: {
          ...currentWS,
          isDirty: true, // Mark workspace dirty
          tabs: currentWS.tabs.map((tab) =>
            tab.id === tab_id
              ? {
                  ...tab,
                  queries: [
                    {
                      id: query_id,
                      query,
                      startTime: Date.now(),
                    },
                    ...(tab.queries || []),
                  ],
                  isDirty: true,
                }
              : tab,
          ),
          lastUpdatedAt: Date.now(),
        },
      }
    },
    updateQuery(state, action) {
      const {
        user_id,
        organization_id,
        tab_id,
        query_id,
        query,
        query_status,
        error,
      } = action.payload
      const stateKey = organization_id
        ? `${organization_id}-${user_id}`
        : user_id
      const currentWS = state[stateKey]

      if (!currentWS?.tabs) return state

      const tabs = currentWS.tabs.map((tab) => {
        if (tab.id !== tab_id) return tab

        const oldQuery = tab.queries?.find((q) => q.id === query_id)
        if (!oldQuery) return tab

        const newQuery = {
          ...oldQuery,
        }
        if (query) newQuery.query = query
        if (query_status) newQuery.query_status = query_status
        if (error) newQuery.error = error

        if (['SUCCESS', 'FAILURE', 'REVOKED'].includes(query_status || '')) {
          newQuery.endTime = newQuery.endTime || Date.now()
        }

        return {
          ...tab,
          queries: (tab.queries || []).map((q) =>
            q.id === query_id ? newQuery : q,
          ),
          isDirty: true,
        }
      })

      return {
        ...state,
        [stateKey]: {
          ...currentWS,
          isDirty: true, // Mark workspace dirty
          tabs,
          lastUpdatedAt: Date.now(),
        },
      }
    },
    cancelQuery(state, action) {
      const { user_id, organization_id, tab_id, query_id } = action.payload
      const stateKey = organization_id
        ? `${organization_id}-${user_id}`
        : user_id
      const currentWS = state[stateKey]

      if (!currentWS?.tabs) return state

      return {
        ...state,
        [stateKey]: {
          ...currentWS,
          isDirty: true, // Mark workspace dirty
          tabs: currentWS.tabs.map((tab) =>
            tab.id === tab_id
              ? {
                  ...tab,
                  queries: (tab.queries || []).map((q) =>
                    q.id === query_id ? { ...q, query_status: 'REVOKED' } : q,
                  ),
                  isDirty: true,
                }
              : tab,
          ),
          lastUpdatedAt: Date.now(),
        },
      }
    },
    updateTabs(state, action) {
      const { user_id, organization_id, tabs } = action.payload
      const stateKey = organization_id
        ? `${organization_id}-${user_id}`
        : user_id
      const currentWS = state[stateKey]

      return {
        ...state,
        [stateKey]: {
          ...currentWS,
          isDirty: true, // Mark workspace dirty
          tabs: tabs.map((tab: any) => ({
            ...tab,
            // Mark them as dirty on the assumption they have changed
            isDirty: true,
          })),
          lastUpdatedAt: Date.now(),
        },
      }
    },
  },
})

export const {
  initWorkspaceState,
  setClean,
  createQueryTab,
  createTableTab,
  changeTabName,
  updateTabCode,
  deleteTab,
  selectTab,
  createQuery,
  updateQuery,
  cancelQuery,
  updateTabs,
} = workspaceStateSlice.actions

export default workspaceStateSlice.reducer
